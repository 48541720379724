<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-04">
      <div class="bms-relaunch-comp-v2">
        <h1 class="page-header">BMS 웹매거진</h1>
        <div class="feature-body">
          <p>BMS에서는 제품 및 관련 질환 동향, 뉴스 등 주요 Summary 콘텐츠로 구성된 웹매거진을 매월 제공합니다.</p>
        </div>
      </div>
    </div>
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb"><a href="#">BMS 소식</a></li>
            <li class="breadcrumb">BMS 웹매거진</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->

      <div class="section bmswebzine">

        <!--본문 영역 s-->

        <div class="month_top">
          <div class="select_area">
            <select name="sch_year" class="sel_cal" v-model="selectYear" @change="initBoard">
              <option v-for="year in (new Date().getFullYear() - 2016 + 1)"
                      :value="new Date().getFullYear() - year + 1" v-text="new Date().getFullYear() - year + 1"
                      :key="new Date().getFullYear() - year + 1"/>
            </select>
            <select name="sch_month" class="sel_cal" v-model="selectMonth" @change="initBoard">
              <option v-for="index in monthCount" :value="index" :key="index">{{
                  String(index).padStart(2, '0')
                }}
              </option>
            </select>
          </div>
          <div class="month_tit">
            <button type="button" class="btn_prev" @click="calendarData(-1)"><span>이전</span></button>
            <span>{{ selectMonth }}월 웹매거진</span>
            <button type="button" class="btn_next" @click="calendarData(1)"><span>다음</span></button>
          </div>
          <div class="icon_area">
          </div>
        </div>
        <div v-html="html"></div>
      </div>
      <!--본문 영역 e-->
    </div>
  </main>
</template>

<script>
import axios from "axios";

export default {
  name: 'WebMagazine',
  data() {
    return {
      selectYear: new Date().getFullYear(),
      selectMonth: new Date().getMonth() + 1,
      html: ''
    }
  },
  computed: {
    monthCount() {
      if (this.selectYear === new Date().getFullYear()) {
        return new Date().getMonth() + 1
      } else {
        return 12
      }
    }
  },
  created() {
    this.initBoard()
  },
  methods: {
    initBoard() {
      this.$store.dispatch('fetchWebMagazine', {
        type: 'web-magazine',
        year: this.selectYear,
        month: this.selectMonth,
      }).then(({html}) => {
        axios.get(html).then(({data}) => this.html = data)
      }).catch(() => {
        this.html = ''
      })
    },
    calendarData(arg) { // 인자를 추가
      if (arg < 0) { // -1이 들어오면 지난 달 달력으로 이동
        this.selectMonth -= 1;
      } else if (arg === 1) { // 1이 들어오면 다음 달 달력으로 이동
        if (this.selectYear === new Date().getFullYear()) {
          if (this.monthCount > this.selectMonth) {
            this.selectMonth += 1;
          }
        } else {
          this.selectMonth += 1;
        }

      }
      if (this.selectMonth === 0) { // 작년 12월
        this.selectYear -= 1;
        this.selectMonth = 12;
      } else if (this.selectMonth > 12) { // 내년 1월
        this.selectYear += 1;
        this.selectMonth = 1;
      }
      this.initBoard()
    },
  },
};
</script>

<style scoped>

</style>
